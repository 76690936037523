/* LMS */

.lms {
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
}

.lms #left-sidebar li,
.lms #left-sidebar,
.lms .navbar-brand {
  width: 100%;
}

.lms .sidebar .navbar .navbar-brand img {
  display: block;
  margin: 0 auto;
}

.lms .sidebar {
  background-color: #FFFFFF;
  height: 100%;
}

.lms .sidebar .navbar .navbar-brand {
  min-height: 100px;
  padding: 10px;
  min-height: 83px;
}

.lms .sidebar #navbar {
  padding: 0px;
}

.lms .sidebar {
  padding: 0px;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
}

#left-sidebar li a {
  font-size: 1em;
}

.block {
  display: block;
}

.lms #navbar,
.top-nav-bar {
  background-color: #FFFFFF;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

.top-nav-bar {
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  position: fixed;
  width: 83.33333333%;
  z-index: 50;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.content {
  margin-top: 25px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding-left: 0px;
  padding-right: 0px;
}

.footer-container {
  padding-left: 1%;
  padding-right: 1%;
}

.inline {
  display: inline;
  padding-right: 1%;
}

.setting-item {
  padding-bottom: 1.5%;
}

.inline-code {
  font-size: 1.0em;
  display: inline;
  padding: 5px;
}

.two-split {
  margin-top: 2%;
}

.center-feature-image {
  margin-top: 50%
}

.content-light-padding {
  padding-top: 2%;
}

#bonus .features {
  background: #fff;
}

.lms .logout-menu {
  right: -10px;
  left: auto;
}

.lms .logout-menu .divider {
  padding: 0px;
}

.lms .content-wrapper {
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 70px;
}

.lms .grid {
  list-style-type: none;
  margin-bottom: 0px;
  padding: 30px;
  padding-left: 40px;
  padding-bottom: 0px;
}

.lms .grid .col-md-3 {
  padding: 0;
  margin-bottom: 20px;
}

.lms .grid .grid-item {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0px;
  margin-right: 10px;
}

.lms .grid .grid-item .logo img {
  padding-top: 10px;
  padding-bottom: 5px
}

.lms .grid img {
  width: 100%;
  height: 100%;
}

.lms .grid .grid-item:hover {
  cursor: pointer;
}

.lms .grid .grid-item .title {
  background-color: #fff;
  opacity: 0.80;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 2.4em;
  padding-left: 5px;
}

.lms .grid .logo {
  position: absolute;
  width: 100%;
  height: auto;
  top: 100px;
}

.lms .grid .number {
  opacity: 0.80;
  position: absolute;
  top: 5px;
  opacity: 0.80;
  right: 15px;
  width: 28px;
  font-size: 1.4em;
  border-radius: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
  z-index: 5;
}

.lms .grid .grid-item:hover .title,
.lms .grid .grid-item:hover .number {
  opacity: 1;
}

.logo img {
  z-index: 1;
  position: relative;
  min-height: 100px;
  max-height: 150px;
  padding-left: 20px;
  padding-right: 20px;
}

.lms .section-content {
  padding-top: 1%;
  padding-left: 1%;
  padding-right: 1%;
}

.fancy-header {
  width: 100%;
  background: #777;
}

.fancy-header .controls .material-icons {
  font-size: 2.0em;
  color: #fff;
  left: 17px !important;
}

.fancy-header .controls .circle {
  z-index: 20;
  line-height: 56px;
  min-width: 0;
  width: 56px;
  height: 56px;
  vertical-align: middle;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  transition: .2s linear;
  transition-property: background-color, box-shadow;
  background-color: #fabd2c;
}

.active-item {
  background-color: #fabd2c !important;
  border: 1px solid #ddd !important;
}

.active-item .list-group-item-text,
.active-item .least-content {
  color: #fff !important;
}

.lms .list-group .list-group-item .row-content {
  min-height: auto !important;
}

.lms .list-group-item {
  padding-bottom: 10px;
  padding-top: 10px;
}

.yellow-btn {
  color: #fff !important;
  background-color: #fabd2c;
}

.yellow-btn:hover {
  color: #777 !important;
}

.number {
  text-align: center;
  font-size: 8em;
  font-weight: 800;
}

.sidebar-fixed {
  position: fixed;
  width: 16.66666667%;
  height: 100%;
}

.video-wrapper #jw-player_controlbar {
  display: none !important;
}

.guide-wizard {
  margin-bottom: 5%;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}

.comment-block a {
  font-size: 1.5em;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
  margin-bottom: 2%;
}

.main-body-content .grid-item .logo #welcome {
  width: auto;
  margin: 0 auto;
  position: relative;
  display: block;
}

.lms .navbar-brand {
  text-align: center;
  font-size: 3em;
  padding-top: 30px !important;
}

.lms .navbar {
  box-shadow: none;
}