body {
  font-size: 14px;
  color: #1a2326;
}

table th,
p {
  margin: 0 0 16px 0;
  font-size: 16px;
  font-weight: 400;
  opacity: .87;
  line-height: 28px;
  padding: 0;
}

.navbar,
.navbar.navbar-default {
  position: relative;
  background-color: #fff;
  border-color: #e7e7e7;
  color: #777777;
  margin: 0px;
  box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
  -webkit-box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
}

.navbar .navbar-brand {
  color: #555;
  font-weight: 900;
}

.navbar i {
  margin-right: 5px;
}

.navbar .navbar-brand img {
  max-width: 100%;
  opacity: 0.90;
  display: inline-block;
  max-height: 100%;
}

.navbar .navbar-brand:hover img {
  opacity: 1;
}

.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: #333;
}

.navbar-toggle {
  padding-right: 0px;
  margin-right: 0px;
}

.navbar .navbar-nav>li>a:hover,
.navbar .navbar-nav>li>a:focus {
  color: #333333;
  background-color: transparent;
}

.nav li {
  padding: 0px;
}

.login {
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  font-weight: 700;
}

a {
  color: #777777;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

a:hover,
a:focus {
  color: #333333;
  background-color: transparent;
  text-decoration: initial;
}

.main-content {
  background-image: url('/images/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.teach {
  text-align: center;
  text-shadow: 3px 1px 0px rgba(0, 0, 0, 0.1);
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  padding: 25px;
}

.teach h1 {
  font-size: 4.0em;
  font-weight: 500;
}

.teach h2 {
  font-size: 3.4em;
  font-weight: 400;
}

.teach h3 {
  font-size: 2.5em;
  color: #fff;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.teach h3:hover {
  color: #FFDB72;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.features {
  background: #eeeeee;
}

.layover-parent {
  position: relative;
}

.layover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #0143A3;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(255, 161, 44, 0.6));
  /*0 3px 4px 0 rgba(), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);*/
}

.layover-v2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #0143A3;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(255, 161, 44, 0.6));
  /*0 3px 4px 0 rgba(), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);*/
  background-image: url('/images/full-stack-developer.jpg');
}

.parent {
  max-width: 100%;
  overflow: hidden;
}

.price .teach {
  min-height: 850px;
  margin-top: 0px;
  padding-top: 20px;
  min-height: 920px;
}

p {
  font-size: 1.35em;
  line-height: 2.3;
}

footer {
  padding: 10px 0 10px 0;
}

.center {
  text-align: center;
}

.countdown-container {
  width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.countdown {
  margin-top: 15px;
  display: inline-block;
  vertical-align: top;
  display: inline-block;
  margin: 0 auto;
  width: 80%;
}

.countdown .bloc-time {
  float: left;
  margin-right: 2%;
  text-align: center;
  width: 23%;
}

.countdown .bloc-time:last-child {
  margin-right: 0;
}

.countdown .count-title {
  display: inline-block;
  margin-bottom: 15px;
  color: #333;
  text-transform: uppercase;
  margin-top: 15px;
}

.countdown .figure {
  position: relative;
  float: left;
  height: 97px;
  width: 47%;
  background-color: #fff;
  border-radius: 8px;
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
}

.countdown .first-figure {
  margin-right: 5%;
}

.countdown .figure:last-child {
  margin-right: 0;
}

.countdown .figure>span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font: normal 3.94em/97px "Lato";
  font-weight: 700;
  color: #FAB615;
}

.countdown .figure .top:after,
.countdown .figure .bottom-back:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.countdown .figure .top {
  z-index: 3;
  background-color: #f7f7f7;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -moz-border-radius-topleft: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  -moz-transform: perspective(200px);
  -ms-transform: perspective(200px);
  -webkit-transform: perspective(200px);
  transform: perspective(200px);
}

.countdown .figure .bottom {
  z-index: 1;
}

.countdown .figure .bottom:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.02);
}

.countdown .figure .bottom-back {
  z-index: 2;
  top: 0;
  height: 50%;
  overflow: hidden;
  background-color: #f7f7f7;
  -moz-border-radius-topleft: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}

.countdown .figure .bottom-back span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.countdown .figure .top,
.countdown .figure .top-back {
  height: 50%;
  overflow: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.countdown .figure .top-back {
  z-index: 4;
  bottom: 0;
  background-color: #fff;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -moz-transform: perspective(200px) rotateX(180deg);
  -ms-transform: perspective(200px) rotateX(180deg);
  -webkit-transform: perspective(200px) rotateX(180deg);
  transform: perspective(200px) rotateX(180deg);
  -moz-border-radius-bottomleft: 10px;
  -webkit-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.countdown .figure .top-back span {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: auto;
}

.hidden {
  display: none;
}

.checkout h1 {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
  text-align: center;
}

.checkout {
  background-position: left bottom;
  background-color: #093A46;
  background-repeat: no-repeat;
  color: #fff;
}

.checkout .least-content {
  color: #fff !important;
}

.checkout .module {}

.gurantee {
  width: 100%;
}

.course ul,
.course ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.course ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.2em;
  text-align: left;
}

.course h4 {
  text-align: left;
}

.total p {
  padding: 0px;
  line-height: 1.0;
  text-align: right;
}

#checkout .form-group {
  text-align: left;
}

.checkout-area {
  background: #fff;
  color: #1a2326;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
  padding: 25px;
}

.thank-you {
  box-sizing: border-box;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
}

#signup {
  width: 60%;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 250px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #FABD2C;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.5s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.5s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.jwlogo {
  margin: -35000px;
  display: none;
}


/* Sales Page */

.parent-wrapper {
  margin-top: 30px;
  width: 80%;
  max-width: 920px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  margin: 0 auto;
  min-width: 285px;
  min-height: 100px;
  padding: 75px 45px 25px 45px;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 1%;
}

.general-wrapper {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
}

.fb-wrapper {
  margin-bottom: 30px;
}

.no-padding {
  padding: 0px;
}

.video-heading {
  font-size: 3em;
  padding: 15px;
  color: #fff;
  font-weight: 400;
}

.main-heading h2 {
  font-weight: 100;
  font-size: 2.8em;
  margin-bottom: 40px !important;
  margin-top: 0px;
  line-height: 65px;
}

.underline {
  text-decoration: underline;
}

.video-player i {
  position: absolute;
  top: 200px;
  font-size: 5em;
  left: 420px;
  color: #fff;
}

.video-player i:hover {
  color: rgb(255, 219, 114);
  transition: color 0.5s ease-out;
}

.left {
  text-align: left;
}

.red {
  color: #e60e0e;
}

.green {
  color: #5fd400;
}

.image-block {
  display: inline-block;
}

.image-block .text-wrapper {
  float: left;
  width: 65%;
}

.image-block img {
  width: 35%;
  padding-left: 25px;
  padding-top: 25px;
}

ul li {
  font-size: 1.25em;
}

.video-wrapper {
  margin-bottom: 30px;
  background: transparent;
}

.center {
  text-align: center !important;
}

.value {
  font-size: 3em;
  margin: -20px;
}

.perspective {
  margin-bottom: 20px;
}

.inline-image {
  max-width: 100%;
}

.pitch {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  text-shadow: rgba(0, 0, 0, 0.0980392) 3px 1px 0px;
  background-image: url(/images/background.svg);
  background-size: cover;
  background-position-x: 80%;
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
  background-repeat: no-repeat;
  padding: 25px;
}

.price .pitch {
  margin-top: 0px;
  padding-top: 20px;
}

.included {
  font-size: 1.2em;
  list-style-type: none;
  padding: 0px;
}

.price-style {
  color: #fff;
  font-size: 4.5em;
  line-height: normal;
  font-weight: 700;
  display: block;
  text-align: center;
}

.limited {
  text-align: center;
  font-size: 2em;
  line-height: 1;
}

.price-bottom {
  position: relative;
  margin-top: 5%;
}

.price-bottom h1 {
  text-align: center;
  font-size: 4em;
}

.price-bottom .cart {
  margin-top: 20px;
  margin-bottom: 20px;
}

.price-bottom a {
  font-size: 2em;
  position: relative;
  display: block;
  margin: 0 auto;
  width: 60%;
}

.payment-acceptance {
  display: block;
  margin: auto;
  width: 60%;
  padding-top: 10px;
}

.buy {
  position: relative;
  margin-top: 5%;
  margin-bottom: 5%;
}

.buy a {
  font-size: 2.5em;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
}

.course-btn {
  position: relative;
  margin-bottom: 5%;
}

.course-btn a {
  font-size: 2.5em;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}

.light-padding {
  margin-top: 30px;
}

.img {
  max-width: 100%;
}


/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 *
*****************************************************************/

.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #307bbb;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #cccccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.c100.center {
  float: none;
  margin: 0 auto;
}

.c100.big {
  font-size: 240px;
}

.c100.small {
  font-size: 80px;
}

.c100>span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: whitesmoke;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}

.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}

.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}

.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}

.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}

.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}

.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}

.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}

.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}

.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}

.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}

.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}

.c100.p13 .bar {
  -webkit-transform: rotate(46.8deg);
  -moz-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  -o-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}

.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}

.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}

.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}

.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}

.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}

.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}

.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}

.c100.p21 .bar {
  -webkit-transform: rotate(75.6deg);
  -moz-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  -o-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}

.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}

.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}

.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}

.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.c100.p26 .bar {
  -webkit-transform: rotate(93.6deg);
  -moz-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  -o-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}

.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}

.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}

.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}

.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}

.c100.p31 .bar {
  -webkit-transform: rotate(111.6deg);
  -moz-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  -o-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}

.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}

.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}

.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}

.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}

.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}

.c100.p37 .bar {
  -webkit-transform: rotate(133.2deg);
  -moz-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  -o-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}

.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}

.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}

.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}

.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}

.c100.p42 .bar {
  -webkit-transform: rotate(151.2deg);
  -moz-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  -o-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}

.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}

.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}

.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}

.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}

.c100.p47 .bar {
  -webkit-transform: rotate(169.2deg);
  -moz-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  -o-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}

.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}

.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}

.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}

.c100.p52 .bar {
  -webkit-transform: rotate(187.2deg);
  -moz-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  -o-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}

.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}

.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}

.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}

.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}

.c100.p57 .bar {
  -webkit-transform: rotate(205.2deg);
  -moz-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  -o-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}

.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}

.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}

.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}

.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}

.c100.p62 .bar {
  -webkit-transform: rotate(223.2deg);
  -moz-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  -o-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}

.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}

.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}

.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}

.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}

.c100.p67 .bar {
  -webkit-transform: rotate(241.2deg);
  -moz-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  -o-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}

.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}

.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}

.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}

.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}

.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}

.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}

.c100.p74 .bar {
  -webkit-transform: rotate(266.4deg);
  -moz-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  -o-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}

.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}

.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}

.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}

.c100.p79 .bar {
  -webkit-transform: rotate(284.4deg);
  -moz-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  -o-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}

.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}

.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}

.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}

.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}

.c100.p84 .bar {
  -webkit-transform: rotate(302.4deg);
  -moz-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  -o-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}

.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}

.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}

.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}

.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}

.c100.p89 .bar {
  -webkit-transform: rotate(320.4deg);
  -moz-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  -o-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}

.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}

.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}

.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}

.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}

.c100.p94 .bar {
  -webkit-transform: rotate(338.4deg);
  -moz-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  -o-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}

.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}

.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}

.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}

.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}

.c100.p99 .bar {
  -webkit-transform: rotate(356.4deg);
  -moz-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  -o-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}

.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.c100:hover {
  cursor: default;
}

.c100:hover>span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #307bbb;
}

.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}

.c100.dark {
  background-color: #777777;
}

.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}

.c100.dark>span {
  color: #777777;
}

.c100.dark:after {
  background-color: #666666;
}

.c100.dark:hover>span {
  color: #c6ff00;
}

.c100.dark-green .bar,
.c100.dark-green .fill {
  border-color: #385151 !important;
}

.c100.dark-green:hover>span {
  color: #385151;
}

.c100.dark-green.dark .bar,
.c100.dark-green.dark .fill {
  border-color: #687b7b !important;
}

.c100.dark-green.dark:hover>span {
  color: #687b7b;
}

.c100.green .bar,
.c100.green .fill {
  border-color: #4db53c !important;
}

.c100.green:hover>span {
  color: #4db53c;
}

.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}

.c100.green.dark:hover>span {
  color: #5fd400;
}

.c100.orange .bar,
.c100.orange .fill {
  border-color: #dd9d22 !important;
}

.c100.orange:hover>span {
  color: #dd9d22;
}

.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}

.c100.orange.dark:hover>span {
  color: #e08833;
}

.course {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.course-wrapper {
  border-color: #ee9302;
  background-color: #fafbfb;
  padding: 10px;
  border-style: solid;
  border-width: 5px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.course p {
  margin: 0px;
  line-height: 25px;
}

.course i {
  font-size: 5em;
}

.course .title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.5em;
}

h1.pricing-title {
  font-size: 2em;
}

.add-to-cart {
  font-size: 2em;
  position: relative;
  display: block;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.welcome-video {
  position: relative;
}

.iframe-wrapper {
  margin-bottom: 30px;
  padding-top: 30px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
}

.checkbox label,
.radio label,
label {
  color: #333;
}

.sale {
  overflow: hidden;
}

.sale .wrapper {
  text-align: center;
  max-width: 1150px;
  margin: 0 auto;
}

.sale .main-content {
  padding: 20px;
}

.sale .main-content h2 {
  font-size: 1.2em;
  color: #fff;
  font-weight: 500;
}

.sale .main-content h3 {
  font-size: 32px;
  color: #fff;
}

.sale .main-heading a {
  font-size: 2.3em;
}

.sale .sales-page {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  margin: 0 auto;
  min-width: 285px;
  min-height: 100px;
  padding: 30px 45px 25px 45px;
  text-align: center;
}

.sale .welcome-video img {
  width: 100%;
}

.sale .course-description {
  background-image: url('/images/diamonds.svg');
  background-repeat: repeat;
}

.course-description h1 {
  margin: 30px;
  font-weight: 400;
}

.email-capture {
  max-width: 920px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  margin: 0 auto;
  min-width: 285px;
  min-height: 100px;
  padding: 20px 10px 20px 10px;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 1%;
}

.source {
  text-transform: none;
  width: 100%;
  font-size: 1.8em;
  text-align: left;
}

.capture-form {
  padding-bottom: 10%;
  padding-top: 10%;
}

.sign-up {
  font-size: 1.0em;
  margin: 30px;
}

.access {
  color: #fff;
  font-size: 3em;
}

.payment-options {
  background-color: #fabd2c !important;
  color: #fff !important;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}

.alignleft {
  float: left;
  text-align: left;
  margin-right: 24px
}

.avatar-125 {
  max-width: 100%;
  border-radius: 50%;
  padding: 10px;
}

.name {
  display: block;
  text-align: center;
}

.inline-page {
  margin-top: 5%;
}

#nav-wizard {
  font-size: 1.2em;
}

#nav-wizard.nav-pills>li:first-child {
  border-left: 0;
}

#nav-wizard.nav-pills>li {
  position: relative;
  overflow: visible;
  border-right: 15px solid #fff;
  border-left: 15px solid #fff;
}

#nav-wizard.nav-pills>li a {
  border-radius: 0;
  background-color: #eee;
  padding: 9px 15px !important;
}

#nav-wizard.nav-pills>li .nav-arrow {
  position: absolute;
  top: 0px;
  right: -20px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 28px 0 20px 20px;
  border-color: transparent transparent transparent #eee;
  z-index: 2;
}

#nav-wizard.nav-pills>li .nav-wedge {
  position: absolute;
  top: 0px;
  left: -20px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 28px 0 20px 20px;
  border-color: #eee #eee #eee transparent;
  z-index: 2;
}

#nav-wizard .active a {
  color: #626E69;
  font-weight: 400;
}

#nav-wizard .available a {
  color: #626E69;
  font-weight: 200;
}

#nav-wizard .available a:hover {
  color: #333;
  font-weight: 300;
}

#nav-wizard .disabled:hover {
  color: #777777;
}

.join-offer .guide {
  margin-bottom: 2%;
  margin-top: 2%;
}

.guide .jwplayer {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}

.left-wrapper {
  padding-left: 0px;
}

.right-wrapper {
  padding-right: 0px;
}

.points {
  font-size: 2.2em;
  margin-top: 5%;
}

.point {
  font-weight: 500;
}

.points span {
  color: #5fd400;
}

.green {
  color: #5fd400;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  padding: 0px;
}

.head-line {
  font-size: 3.4em;
  font-weight: 400;
  line-height: 70px;
  padding-bottom: 30px;
}

.question-headline {
  font-size: 3em;
  color: #fff;
  font-weight: 400;
}

.question-headline a {
  color: #eff0f0;
}

.question-headline a:hover {
  color: #fff;
}

.white {
  color: #fff;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.post-meta {
  display: none;
}

.blank-canvas {
  max-width: 920px;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  min-width: 285px;
  min-height: 100px;
  padding: 30px 45px 25px 45px;
  margin-top: 1%;
  margin-bottom: 1%;
}

.quote {
  border: 1px solid #d5d5d5;
  padding: 16px;
  z-index: 10;
  position: relative;
  font-size: 1.0em;
  background: #f9f9f9;
  border-left: 3px solid #ccc;
  font-style: italic;
}

.pro-quotes {
  font-size: 1.5em;
}

.quote p {
  margin-bottom: 0px;
}

.author {
  float: right;
  margin-right: -40px;
  width: 80px;
  text-align: center;
  margin-top: 10px;
}

.author-img {
  border-radius: 50%;
  padding: 10px;
  max-height: 80px;
  margin-top: 15px;
}

.arrow {
  transform: rotate(130deg);
  border-color: inherit;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: '';
  display: block;
  height: 18px;
  width: 18px;
  border: 1px solid #d5d5d5;
  padding: 20px;
  z-index: 1;
  background: #f9f9f9;
  position: relative;
  float: right;
  top: -25px;
  left: -20px;
}

.guide-wrapper {
  margin-top: 2%;
}

.pitch h1 {
  font-size: 2.0em;
  padding-bottom: 10px;
}

.pitch ul {
  padding-left: 0px;
  list-style-type: none;
  font-size: 1.1em;
  padding-left: 15px;
}

.pitch b {
  font-weight: 400;
}

.payment-options-btns .col-md-4 {
  padding: 0px;
  padding-left: 10px;
}

.getting-started {
  box-sizing: border-box;
  background: #fff;
  text-align: center;
  margin: 0 auto;
}

.getting-started .main-content-wrapper {
  padding: 60px;
}

.presentation-wrapper {
  font-size: 3em;
  color: #fff;
}

.sales-page-wrapper {
  box-sizing: border-box;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 1%;
  max-width: 1150px;
}

.content-wrapper {
  padding: 30px 45px 25px 45px;
}

.benefits {
  padding-left: 0px;
  color: #fff;
}

.benefit-box {
  float: left;
  margin-top: 2px;
}

.benefits li {
  list-style: none;
  margin-top: 25px;
  font-size: 1.1em;
  display: flex;
}

.benefits li span {
  font-size: 1.3em;
  margin-left: 10px;
}

.content-wrapper img {
  width: 100%;
}

.included-inside {
  margin-top: 30px;
}

.included-inside .description {
  line-height: 25px;
  margin-top: 10px;
}

.enroll-full {
  width: 100%;
  font-size: 3em;
}

.learn-more a {
  font-size: 3em;
  margin-top: 2%;
}

#snackbar {
  visibility: hidden;
  max-width: 300px;
  background-color: #0E81B2;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  top: 30px;
  right: 20px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 5.5s;
  animation: fadein 0.5s, fadeout 0.5s 5.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }

  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }

  to {
    top: 0;
    opacity: 0;
  }
}

.guarantee {
  font-size: 3.2em;
  margin-bottom: 0px;
  font-weight: 400;
  padding: 10px;
}

.inline-in {
  margin-left: 10px;
}

.panel-group .panel {
  margin-bottom: 15px;
}

.panel>.panel-heading,
.panel.panel-default>.panel-heading {
  background-color: #FAB615;
  color: #fff;
}

.panel-title {
  font-size: 1.5em;
}

.panel-body {
  text-align: left;
}

.webinar .learn-more {
  padding-bottom: 30px;
}

.hour-of-code {
  padding: 25px;
  z-index: 1;
}

.hour-of-code h1 {
  font-weight: 500;
  font-size: 4em;
  margin: 0px;
}

.hour-of-code h3 {
  font-size: 3em;
  margin: 0px;
}

.register-btn {
  font-size: 3em;
}

.hour-of-code small {
  margin-top: 10px;
}

#sub-navbar ul {
  text-align: center;
  font-size: 1.2em;
}

#sub-navbar .item img {
  height: 30px;
}

#sub-navbar li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
  margin-left: 20px;
}


#sub-navbar li img {
  margin-right: 3px;
}

#sub-navbar li a {
  color: #fff;
}

.sub-nav {
  background: #374868;
  padding: 25px;
}

.read-me p {
  text-align: left;
  font-family: 'Ubuntu Mono',
    monospace;
}

.read-me {
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f5f5f5;
  display: inline-block;
  margin-bottom: 20px;
}

.cart .btn-primary {
  font-size: 3em;
}

.payment-acceptance {
  max-width: 310px;
}