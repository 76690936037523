@media (max-width: 736px) {

  /* styles for mobile browsers smaller than 736px;*/
  .parent-wrapper {
    width: 100%;
  }

  .video-player i {
    top: 90px;
    font-size: 2.75em;
    left: 42%;
    color: #fff;
  }

  .image-block img {
    width: 100%;
  }

  .image-block .text-wrapper {
    width: 90%;
  }

  .price-style {
    font-size: 4.5em;
  }

  #signup {
    width: 100%;
  }

  .price-bottom img {
    width: 100%;
  }

  .price-bottom a {
    width: 100%;
  }

  .course-description .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #sign-up {
    font-size: 2.5em;
  }

  .access {
    font-size: 2.0em;
  }

  .course-description h1 {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 36px;
    margin: .67em 0;
  }

  .sale .sales-page {
    width: 100%;
  }

  .sale .course-wrapper {
    padding: 10px;
  }

  #sales-page-wrapper .sign-up {
    font-size: 20px;
  }

  #sales-page-wrapper .benefits {
    display: none;
  }

  #capture-form p {
    display: none;
  }

  #capture-form .panel-body {
    overflow: hidden;
  }

  #sub-navbar li {
    display: inline-block;
    list-style: none;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  #sub-navbar ul {
    text-align: center;
    font-size: 1.2em;
    text-align: left;
    padding: 0px;
    margin: 0px;
  }

  .navbar-header {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 10px;
  }

  .sub-nav {
    background: #374868;
    padding: 15px;
  }

}

@media (max-width: 540px) {

  /* styles for mobile browsers smaller than 540px;*/
  .countdown .figure>span {
    font: normal 2.94em/107px "Lato";
  }

  .teach h2 {
    font-size: 2.2em;
    font-weight: 400;
  }

  .teach h1 {
    font-size: 2.8em;
  }

  .countdown {
    width: 95%;
  }

  .thank-you {
    padding: 50px;
    margin: 0px;
  }

  .countdown .figure {
    height: 60px;
  }

  .countdown .figure>span {
    font: normal 2.94em/60px "Lato";
  }
}

@media (max-width: 1674px) {

  /* styles for mobile browsers smaller than 1674px;*/
  #nav-wizard {
    font-size: 1.0em;
  }

  #nav-wizard.nav-pills>li .nav-arrow {
    border-width: 23px 0 20px 20px;
  }

  #nav-wizard.nav-pills>li .nav-wedge {
    border-width: 23px 0 20px 20px;
  }

  .points {
    font-size: 1.9em;
  }

  .social-share a {
    font-size: 2em;
  }
}

@media (max-width: 1650px) {

  /* styles for mobile browsers smaller than 1650px;*/
  .lms .grid .grid-item .title {
    font-size: 1.8em !important;
  }

  .source {
    font-size: 1.5em;
  }
}

@media (max-width: 1500px) {

  /* styles for mobile browsers smaller than 1500px;*/
  .lms .grid .logo {
    top: 50px !important;
  }

  .source {
    font-size: 1.2em;
  }
}

@media (max-width: 1444px) {

  /* styles for mobile browsers smaller than 1444px;*/
  #nav-wizard {
    font-size: 0.85em;
  }

  #nav-wizard.nav-pills>li .nav-arrow {
    border-width: 19px 0 20px 20px;
  }

  #nav-wizard.nav-pills>li .nav-wedge {
    border-width: 19px 0 20px 20px;
  }

  .points {
    font-size: 1.6em;
  }
}

@media (max-width: 1300px) {

  /* styles for mobile browsers smaller than 1300px;*/
  .grid .col-md-3 {
    width: 32.33%;
    margin-right: 1.0%;
  }

  .lms .grid .logo {
    top: 80px !important;
  }

  .source {
    font-size: 1.0em;
  }

  .guide-wrapper {
    width: 100%;
    margin: 0px;
    padding: 4%;
  }

  #nav-wizard {
    font-size: 1.2em;
  }

  #nav-wizard.nav-pills>li .nav-arrow {
    border-width: 28px 0 20px 20px;
  }

  #nav-wizard.nav-pills>li .nav-wedge {
    border-width: 28px 0 20px 20px;
  }

  .points {
    font-size: 2.2em;
  }

  .social-share a {
    font-size: 1.5em;
  }
}

@media (max-width: 1172px) {

  /* styles for mobile browsers smaller than 1172px;*/
  #nav-wizard {
    font-size: 0.9em;
  }

  #nav-wizard.nav-pills>li .nav-arrow {
    border-width: 20px 0 20px 20px;
  }

  #nav-wizard.nav-pills>li .nav-wedge {
    border-width: 20px 0 20px 20px;
  }

  .points {
    font-size: 2.0em;
  }
}

@media (max-width: 1006px) {

  /* styles for mobile browsers smaller than 1006px;*/
  #nav-wizard {
    font-size: 1.0em;
  }

  #nav-wizard.nav-pills>li .nav-arrow {
    border-width: 23px 0 20px 20px;
  }

  #nav-wizard.nav-pills>li .nav-wedge {
    border-width: 23px 0 20px 20px;
  }
}

@media (max-width: 1100px) {

  /* styles for mobile browsers smaller than 1300px;*/
  .lms .grid .logo {
    top: 40px !important;
  }

  .points {
    font-size: 1.5em;
  }

  .social-share a {
    font-size: 1.2em;
  }
}

@media (max-width: 991px) {

  /* styles for mobile browsers smaller than 992px;*/
  .sidebar {
    display: none;
  }

  .lms .logout-menu {
    right: 5px !important;
    left: auto;
  }

  .grid .col-md-3 {
    float: left;
  }

  .lms .grid .logo {
    top: 30px !important;
  }

  .top-nav-bar {
    width: 100% !important;
  }

  .source {
    font-size: 2.0em;
    text-align: center;
  }

  .points {
    font-size: 2.0em;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .right-wrapper {
    padding-left: 5px;
  }

  .pro-quotes-wrapper {
    margin-bottom: 150px;
  }

  .course-description .wrapper {
    padding: 30px;
  }

  .sale .sales-page {
    width: 100%;
  }

  #promo-video {
    height: 500px;
  }

  .social-share a {
    font-size: 2.2em;
  }

  .social-share {
    margin-top: 30px;
  }

  .social-share img {
    display: none;
  }

  .main-content {
    padding: 0;
  }

  .learn-more {
    margin-top: 20px;
  }
}

@media (max-width: 990px) {

  /* styles for mobile browsers smaller than 990px;*/
  .lms .grid .logo {
    top: 75px !important;
  }
}

@media (max-width: 890px) {

  /* styles for mobile browsers smaller than 850px;*/
  .lms .grid .logo {
    top: 50px !important;
  }
}

@media (max-width: 850px) {

  /* styles for mobile browsers smaller than 850px;*/
  .grid .col-md-3 {
    width: 49%;
    margin-right: 1.0%;
  }

  .lms .grid .logo {
    top: 100px !important;
  }

  #nav-wizard {
    font-size: 0.9em;
  }

  #nav-wizard.nav-pills>li .nav-arrow {
    border-width: 20px 0 20px 20px;
  }

  #nav-wizard.nav-pills>li .nav-wedge {
    border-width: 20px 0 20px 20px;
  }

  #promo-video {
    height: 430px;
  }
}

@media (max-width: 768px) {

  /* styles for mobile browsers smaller than 768px;*/
  .lms .content-wrapper {
    margin-top: 0px !important;
  }

  .top-nav-bar {
    position: relative !important;
  }

  .content {
    margin-top: 10px !important;
  }

  #nav-wizard.nav-pills>li .nav-arrow {
    display: none;
  }

  #nav-wizard.nav-pills>li .nav-wedge {
    display: none;
  }

  #nav-wizard.nav-pills>li {
    width: 100%;
    border: none;
    margin: 0px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  #nav-wizard.nav-pills>li a {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #ff9800;
    color: #fff;
  }

  #nav-wizard.nav-pills>li a:hover {
    color: rgba(0, 0, 0, 0.14);
  }

  #nav-wizard.nav-pills>li.active a {
    color: #fff;
  }

  .left-wrapper {
    padding-right: 0px;
  }

  .guide {
    margin-left: 1%;
    margin-right: 1%;
  }

  #promo-video {
    height: 375px;
  }
}

@media (max-width: 580px) {

  /* styles for mobile browsers smaller than 550px;*/
  .grid .col-md-3 {
    width: 99%;
  }

  .top-nav-bar {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }

  .course-description .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  .sale .sales-page {
    width: 100%;
  }

  .value {
    font-size: 2.0em;
  }

  .head-line {
    font-size: 2.0em;
    line-height: 35px;
  }

  .course-description h1 {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 1.90em;
    margin: 0px;
  }

  #promo-video {
    max-height: 268px;
  }

  .learn-more {
    font-size: 10px;
  }


}